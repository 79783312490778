<script lang="ts" setup>
import { autoUpdate, offset, shift, useFloating } from '@floating-ui/vue'
import { Icon } from '@ipr-marsproxies/design-system'
import { ref } from 'vue'

export interface NavItemProps {
	triggerLabel: string
}

defineProps<NavItemProps>()

const trigger = ref<HTMLElement>()
const popup = ref<HTMLDivElement>()

const { floatingStyles } = useFloating(trigger, popup, {
	middleware: [
		shift(),
		offset({
			crossAxis: 8,
		}),
	],
	whileElementsMounted: autoUpdate,
})
</script>

<template>
	<div class="group">
		<div ref="trigger" class="cursor-pointer focus-visible:ring" role="presentation" tabindex="0">
			<div
				class="nav-popup-trigger flex w-fit cursor-pointer items-center gap-4 truncate text-neutral-0 group-hover:text-brand-300"
			>
				{{ triggerLabel }}

				<Icon class="transition-transform group-hover:rotate-180" icon="expand-more" />
			</div>
		</div>

		<div
			ref="popup"
			role="menu"
			:style="floatingStyles"
			class="z-10 hidden max-w-[calc(100vw-16px)] overflow-x-auto group-hover:block"
		>
			<div class="h-[36px]" />
			<div class="nav-popup rounded-16 border border-neutral-600 bg-neutral-800 p-32">
				<slot name="sections" />
			</div>
		</div>
	</div>
</template>

<style scoped>
.nav-popup {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
	animation: slideIn 0.2s cubic-bezier(0.05, 0.7, 0.1, 1);
}

html[data-ff-home-light] .nav-popup {
	background: theme('colors.neutral.0');
	border-color: theme('colors.neutral.100');
}

html[data-ff-home-light] .nav-popup-trigger {
	color: theme('colors.neutral.1000');
}

html[data-ff-home-light] .nav-popup-trigger:hover {
	color: theme('colors.brand.300');
}

@keyframes slideIn {
	from {
		opacity: 0;
		transform: translateY(-40px) scale(0.9);
	}
	to {
		opacity: 1;
		transform: none;
	}
}
</style>
